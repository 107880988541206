<template>
  <div>
    <!--    logo和导航栏-->
    <div :class="show ? 'navTitle' : 'navTitle1'">
      <navigation />
    </div>
    <div>
      <imageCom />
    </div>
    <div class="content-father">
      <div class="tab">
        <joinNavigation></joinNavigation>
      </div>
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
    <!--    页脚-->
    <div>
      <footers />
    </div>
  </div>
</template>
<script>
import navigation from '@/components/home/navigation/index';
import footers from '@/components/home/footers/index';
import joinNavigation from "../../components/home/navigation/joinNavigation";
import { foregroundNavigationDetails } from "../../api/homeNav";
import { scrollTop } from "../../utils/scrollTop";
import imageCom from "./imageCom";
export default {
  components: {
    navigation,
    footers,
    joinNavigation,
    imageCom,
  },
  data() {
    return {
      show: false,
      picId:null,
      dataObj:{},
    }
  },
  name: "index",
  watch:{
    //有些情况不触发--》
    $route(to,from){
      this.picId = this.$route.query.picId
    }
  },
  created() {
    this.picId = this.$route.query.picId
    if(this.picId != null) {
      localStorage.setItem('joinUsId', this.picId)
    }
    let localId = localStorage.getItem('joinUsId')
    if(localId != null){
      foregroundNavigationDetails(localId).then(res => {
        this.dataObj = res
      })
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 100) {
        this.show = true
      } else {
        this.show = false
      }
    })
    // // scrollTop(this.show);
    // let oldTop = 0; //旧数据，初始为0
    // // 将自定义方法绑定到窗口滚动条事件
    // window.onscroll = () => {
    //   let top = document.scrollingElement.scrollTop; //触发滚动条，记录数值
    //   //旧数据大于当前位置，表示滚动条top向上滚动
    //   if (oldTop > top) {
    //     this.show = false;
    //     console.log("↑");
    //     // top: -18%;
    //   } else {
    //     //相反...
    //     this.show = true;
    //     console.log("↓");
    //   }
    //   oldTop = top;//更新旧的位置
    // }
  }
}
</script>

<style scoped lang="less">
.tab{
  width: 1200px;
  margin: 0 auto;
  //margin-left: 10%;

}
.content-father{
  //display: flex;
  //flex-direction: column;
  //flex-wrap: wrap;
  //justify-content: flex-start;
  //align-content: center;
  //align-items: flex-start;
}
.content{
  width: 1200px;
  margin: 0 auto;
  //display: flex;
  //flex-direction: row;
  //justify-content: center;
  //margin-bottom: 200px;
  /deep/.wrap{
    margin-bottom: 200px;
  }
}
</style>
