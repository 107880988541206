import { render, staticRenderFns } from "./imageCom.vue?vue&type=template&id=a6658c4a&scoped=true&"
import script from "./imageCom.vue?vue&type=script&lang=js&"
export * from "./imageCom.vue?vue&type=script&lang=js&"
import style0 from "./imageCom.vue?vue&type=style&index=0&id=a6658c4a&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6658c4a",
  null
  
)

export default component.exports