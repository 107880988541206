<template>
  <div>
    <div class="navigation">
      <!--      <div class="left">-->
      <!--        <img class="logo" src="../../../static/image/logo/logo(1).jpg" alt="" @click="logoClick('/')">-->
      <!--        <img src="../../../static/image/logo/logo.jpg" alt="">-->
      <!--        <div>-->
      <!--          <p>logo</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="right">
        <!--        导航栏-->
        <div class="navigationBar">
          <ul @mouseleave="mouseleave">
            <li @mouseenter="enter(1)" @click="routerPush('/join/contact', 1)">
              <p :class="this.index == 1 ? 'p1' : ''">联系我们</p>
            </li>
            <li @mouseenter="enter(2)" @click="routerPush('/join/recruit', 2)">
              <p :class="this.index == 2 ? 'p1' : ''">招纳贤士</p>
            </li>
          </ul>
          <div ref="border" :class="classId != 0 ? 'border' : 'borderClass'"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      activeIndex2: '1',
      id: 1,
      classId: 1,
      // list: 26,
      list: 14,
      index: 1,
      routeList: [
        {
          path: '/join/contact',
          index: 1
        },{
          path: '/join/recruit',
          index: 2
        }
      ]
    }
  },
  mounted() {
    this.$refs.border.style.cssText = "margin-left:" + this.list + 'px'
    this.routeList.forEach(res => {
      if(res.path == this.$route.path) {
        this.enter(res.index)
        this.index = res.index
      }
    })
  },
  methods: {
    // 导航栏跳转
    routerPush(name) {
      if(this.$route.path != name) {
        this.$router.push({ path: name })
        // this.$router.push({ name: name, query: { id: id + '.html' } })
        this.routeList.forEach(res => {
          if(res.path == name) {
            this.enter(res.index)
            this.index = res.index
          }
        })
      }
    },
    // 点击logo跳转首页
    logoClick(path) {
      this.$router.push({ path: path })
    },
    enter(index) {
      if(index > this.id) {
        this.list += (index - this.id) * 90
        this.$refs.border.style.cssText = "margin-left:" + this.list + 'px'
      }
      if(index < this.id) {
        this.list -= (this.id - index) * 90
        this.$refs.border.style.cssText = "margin-left:" + this.list + 'px'
      }
      this.id = index
    },
    mouseleave() {
      this.enter(this.index)
    }
  }
}
</script>

<style lang="less" scoped>
.navigation {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: -7px;
  display: flex;
  justify-content: space-between;
  //border-bottom: 1px solid rgba(61,61,61,.15);
  .left {
    display: flex;
    align-items: center;
    .logo {
      margin: 0;
      width: 169px;
      height: 50px;
      margin-top: -13px;
    }
    .logo:hover {
      cursor: pointer;
    }
    div {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      p {
        //text-align: center;
        font-size: 25px;
        color: #000000;
        cursor: pointer;
        font-weight: 600;
      }
    }
    img {
      margin-left: 10px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .navigationBar {
      height: 100%;
      ul {
        display: flex;
        height: 100%;
        li {
          height: 100%;
          width: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            text-align: center;
            font-size: 16px;
            color: rgba(102, 102, 102, 1);
            cursor: pointer;
          }
          .p1 {
            color: #4B89DA;
          }
        }
        li:hover {
          cursor:pointer;
        }
        li:hover p {
          color: #7384AB;
        }
      }
      .border {
        width: 63px;
        border-bottom: 2px solid #4B89DA;
        transition: all .5s;
        //margin-left: 26px;
      }
      .borderClass {
        display: none;
      }
    }
  }
}

</style>
